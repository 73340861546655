<template>
    <template-base style="padding: 8px !important" fluid>
        <transition name="fade">
            <floating-panel :title="mostrandoAlarmes ? `Alarmes do concentrador ${concentradorAlarme.nome}` : `Localização dos detectores do concentrador ${concentradorAlarme.nome}`" :left="100" :top="50" :width="500" :height="450" v-if="p3" @close="stopMeasurement(); p3=false" :active.sync="pa">
                <button type="button" class="btn btn-outline-secondary w-100 mb-4" @click="mostrandoAlarmes = !mostrandoAlarmes">
                    {{ mostrandoAlarmes ? 'Mostrar localização dos detectores' : 'Mostrar alarmes' }}
                </button>
                
                <span v-if="mostrandoAlarmes">
                    <p class="text-center" v-if="alarmes.length === 0">
                        Nenhum alarme ativo para este concentrador.
                    </p>
                    <div v-for="alarme in alarmes" :key="alarme.id">
                        <button type="button" class="btn btn-outline-secondary w-100 mb-1" @click="showDetalhesAlarme(alarme)">{{ alarme.severidade }} de {{ alarme.classeAlarme.toLowerCase() }} no dispositivo {{ alarme.nomeDispositivo }} </button>
                        <vue-slide-up-down :active="descricaoAlarmeAtiva === alarme.id">
                            <table class="table table-sm">
                                <tr><td>Data/hora inicial</td><td>{{ alarme.date }}</td></tr>
                                <tr><td>Data/hora final</td><td>{{ alarme.dateFinal }}</td></tr>
                                <tr><td>Data/hora reconhecimento</td><td>{{ alarme.dateReconhecimento }}</td></tr>
                                <tr><td>Tag</td><td>{{ alarme.id }}</td></tr>
                                <tr><td>Descrição</td><td>{{ alarme.descricao }}</td></tr>
                                <tr><td><AlertTriangleIcon v-if="alarme.idConversor" /> Conversor</td><td>{{ alarme.nomeConversor || '-' }}</td></tr>
                                <tr><td><AlertTriangleIcon v-if="alarme.idConcentrador" /> Concentrador</td><td>{{ alarme.nomeConcentrador || '-' }}</td></tr>
                                <tr><td><AlertTriangleIcon v-if="alarme.idDetector" /> Detector</td><td>{{ alarme.nomeDetector || '-' }}</td></tr>
                                <tr><td>Área</td><td><AreaHierarquica :id="alarme.idArea" :nome="alarme.nomeArea" /></td></tr>
                                <tr v-if="alarme.extra1"><td>{{alarme.extra1}}</td><td>{{alarme.extra2}}</td></tr>
                                <tr v-if="alarme.classeAlarme === 'Vazamento de gás'"><td>Medição atual</td><td>{{ medicaoTempoReal }}</td></tr>
                            </table>
                        </vue-slide-up-down>
                    </div>
                </span>
                <span v-else>
                    <vue-slide-up-down active>
                    <table class="table table-sm">
                            <tr><td><b>Detector</b></td><td><b>Localização</b></td></tr>
                            <tr v-for="detector in concentradorAlarme.detectores" :key="detector.id" class="pt-2 pb-2">
                                <td>{{ detector.nome }}</td>
                                <td>
                                    <div class="d-flex" style="justify-content: space-between;">
                                        <div class="w-100 mr-4">
                                            <span v-if="editandoDetector[detector.id] === undefined" style="white-space: pre;">{{ detector.localizacao }}</span>
                                            <div v-else class="grow-wrap w-100">
                                                <textarea maxlength="240" class="form-control" placeholder="Localização" v-model="detector.localizacao" onInput="this.parentNode.dataset.replicatedValue = this.value" onClick="this.parentNode.dataset.replicatedValue = this.value" />
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <Edit3Icon v-if="editandoDetector[detector.id] === undefined" size="20" alt="Editar" class="icon-btn" @click="$set(editandoDetector, detector.id, detector.localizacao)" />
                                            <XCircleIcon v-if="editandoDetector[detector.id] !== undefined" size="20" alt="Cancelar" class="icon-btn mr-1" @click="{ detector.localizacao = editandoDetector[detector.id]; $set(editandoDetector, detector.id, undefined);  }" />
                                            <CheckCircleIcon v-if="editandoDetector[detector.id] !== undefined" size="20" alt="Salvar" class="icon-btn" @click="salvarLocalizacao(detector)" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </vue-slide-up-down>
                </span>
            </floating-panel>
        </transition>
        <transition name="fade">
            <floating-panel title="Concentradores" :left="20" :top="20" :width="350" :height="450" v-if="p1" @close="p1=false" :active.sync="pa">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Área</th>
                            <th colspan="2">Concentradores</th>
                        </tr>
                    </thead>
                    <tbody v-for="ca in concentradoresByArea" :key="ca[0]">
                        <tr v-for="(c, i) in ca[1]" :key="c.id">
                            <td :rowspan="ca[1].length" v-if="i === 0" class="align-middle">
                                <area-hierarquica :id="c.idArea" :nome="c.area.nome" />
                            </td>
                            <td>{{ c.tipicoConcentrador.nome }}</td>
                            <td>{{ c.nome }}</td>
                        </tr>
                    </tbody>
                </table>

            </floating-panel>
        </transition>
        <transition name="fade">
            <floating-panel title="Pontos de urgência" :left="400" :top="20" :width="350" :height="450" v-if="p2" @close="p2=false" :active.sync="pa">
                <table class="table table-sm table-striped table-borderless">
                    <thead>
                        <tr>
                            <th>Número</th>
                            <th>Descrição</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="ponto in pontos" :key="ponto[0]">
                            <td>{{ponto[0]}}</td> <td>{{ponto[1]}}</td>
                        </tr>
                    </tbody>
                </table>
            </floating-panel>
        </transition>
        <div class="d-flex justify-content-between align-items-center relogio">
            <div class="text-secondary">Última atualização em {{ ultimaAtualizacao ? formatter(ultimaAtualizacao) : '- ' }}.</div>
            <div>
                <Clock/>
            </div>
        </div>
        <div class="d-flex sin-con sinotico" ref="sinotico">
            <div class="p-1 mapa" v-show="!isMapHidden">
                <mapa
                    ref="mapa"
                    :is-hidden="!shown"
                    :concentradores="concentradores"
                    @zoom="zoom"
                    @showAlarmsInfo="showAlarmsInfo"
                    @info="p1=p2=true"
                    @hide="shown=false; $refs.mapa.resize()"
                    @show="shown=true; $refs.mapa.resize()"
                />
            </div>
            <div class="log-container" :class="isMapHidden ? 'ml-0' : 'ml-2'" v-show="shown">
                <div class="log-content h-100 mb-2 logs">
                    <div class="d-flex align-items-center px-2 justify-content-between">
                        <div class="d-flex align-items-center">
                            <button
                                class="btn rounded-circle p-0 b-0 mr-1"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Mostrar mapa"
                                v-show="isMapHidden"
                                @click="isMapHidden=false; $refs.mapa.resize()"
                            >
                                <ArrowRightCircleIcon />
                            </button>
                            <button
                                class="btn rounded-circle p-0 b-0 mr-1"
                                data-toggle="tooltip"
                                data-placement="left"
                                title="Ocultar mapa"
                                v-show="!isMapHidden"
                                @click="isMapHidden = true"
                            >
                                <ArrowLeftCircleIcon />
                            </button>
                            <h5 class="mx-0 my-1">Log de eventos</h5>
                        </div>
                        <div class="d-flex align-items-center">
                            <button
                                class="btn rounded-circle p-0 b-0 btn-zoom"
                                title="Aumentar fonte"
                                @click="$store.commit('setFontScale', $store.state.fontScale + 0.1)"
                            >
                                <ZoomInIcon />
                            </button>
                            <button
                                class="btn rounded-circle p-0 b-0 ml-1 btn-zoom"
                                title="Diminuir fonte"
                                @click="$store.commit('setFontScale', $store.state.fontScale - 0.1)"
                            >
                                <ZoomOutIcon />
                            </button>
                            <button
                                class="btn rounded-circle p-0 ml-1 max"
                                data-toggle="tooltip"
                                data-placement="left"
                                title="Colocar em tela cheia"
                                @click="max"
                                v-show="isMapHidden"
                            >
                                <MaximizeIcon />
                            </button>
                            <button
                                class="btn rounded-circle p-0 ml-1 min"
                                data-toggle="tooltip"
                                data-placement="left"
                                title="Sair da tela cheia"
                                @click="min"
                                v-show="isMapHidden"
                            >
                                <MinimizeIcon />
                            </button>
                        </div>
                    </div>
                    <hr class="m-0">
                    <div class="msg-log px-2" v-if="firstLoaded">
                        <AlarmItem v-for="log in logs" :key="log.id" :source="log" />
                    </div>
                    <div class="msg-log px-2" v-else>
                        <tb-skeleton shape="radius" v-for="i in 10" :key="i" style="background-color: #dcdcdc; height: 90px; width: 100%; margin: 8px 0;" theme="opacity"></tb-skeleton>
                    </div>
                </div>

            </div>
        </div>
        <!------------------------------------------- Legenda das cores dos alarmes -------------------------------------------->
        <div :class="showLegenda ? 'justify-content-between' : 'justify-content-end'" class="d-flex align-items-center mt-2">
            <div v-if="showLegenda" class="d-flex legenda px-2">
                <div class="titulo-legenda" style="font-size: 18px">
                    Legenda
                    <hr class="m-1">
                </div>
                <div>
                    <div class="rectangle" style="background-color: #d32f2f;"></div><span style="margin-right: 10px;"> Alarme de vazamento de gás </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #ffeb3b;"></div><span style="margin-right: 10px;"> Alerta de vazamento de gás </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #0277bd;"></div><span style="margin-right: 10px;"> Alarme de dispositivo </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #81d4fa;"></div><span style="margin-right: 10px;"> Alerta de dispositivo </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #ff8f00;"></div><span style="margin-right: 10px;"> Alerta de calibração </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #7b1fa2;"></div><span style="margin-right: 10px;"> Alerta de manutenção </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #757575;"></div><span> Alertas/Alarmes reconhecidos </span>
                </div>
            </div>
            <div>
                <button
                    class="btn btn-light btn-legenda rounded-circle p-0 m-0"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Exibir/ocultar legenda"
                    @click="toggleLegenda()"
                >
                    <EyeIcon v-if="showLegenda" size="16"/>
                    <EyeOffIcon v-else size="16"/>
                </button>
                <button
                    class="btn btn-light btn-som rounded-circle p-0"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Sons de alarmes"
                    @click="toggleSound()"
                    style="margin-left: 10px;"
                >
                    <Volume2Icon v-if="makeSound" size="16"/>
                    <VolumeXIcon v-else size="16"/>
                </button>
            </div>
        </div>
        <!--------------------------------------------------- Fim da legenda -------------------------------------------------->
    </template-base>
</template>

<script>
import TemplateBase from "../templates/Base";
import AreaHierarquica from "../components/AreaHierarquica";
import FloatingPanel from "../components/Sinotico/FloatingPanel";
import Clock from "../components/Sinotico/Clock";
import AlarmItem from "../components/Sinotico/AlarmItem";
import Mapa from "../components/Sinotico/Mapa";
import axios from 'axios';
import api from '@/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import VueSlideUpDown from 'vue-slide-up-down';
import loginService from '@/services/login';
import AlarmSound from '../assets/sounds/alarm-sound.mp3'


/** @type {WebSocket|null} */

export default {
    components: {
        TemplateBase,
        AreaHierarquica,
        AlarmItem,
        FloatingPanel,
        Clock,
        Mapa,
        VueSlideUpDown
    },
    data () {
        return {
            p1: false,
            p2: false,
            p3: false,
            showLegenda: true,
            pa: 1,
            logs: [],
            shown: true,
            isMapHidden: false,
            concentradores: [],
            concentradoresTipicos: {},
            allConcentradores: [],
            nZoom: 0,
            alarme: {},
            concentradorAlarme: {},
            descricaoAlarmeAtiva: -1,
            medicaoTempoReal: '',
            firstLoaded: false,
            ultimaAtualizacao: null,
            time: 0,
            realTimeMeasurementWS: null,
            makeSound: localStorage.getItem('utilities/sinotico/som') === 'true',
            audio: new Audio(AlarmSound),

            mostrandoAlarmes: true,
            editandoDetector: {},
            salvandoDetector: {},

            alarmColors: {
                'Alarme de Vazamento de gás': {
                    color: '#d32f2f',
                },
                'Alerta de Vazamento de gás': {
                    color: '#ffeb3b',
                },
                'Alarme de Dispositivo': {
                    color: '#0277bd',
                },
                'Alerta de Dispositivo': {
                    color: '#81d4fa',
                },
                'Alerta de Calibração': {
                    color: '#ff8f00',
                },
                'Alerta de Manutenção': {
                    color: '#7b1fa2',
                },
            },
            pontos: require('@/assets/json/pontosEncontro.json').map(v=>v.map(s=>s.toUpperCase())),
        }
    },
    async mounted() {
        // desativa o padding de altura da página
        document.getElementById('app-bottom-padding').style.display = 'none';
        if (window.innerWidth <= 790) {
            this.showLegenda = false;
            this.shown = false;
            this.$refs.mapa.resize();
            this.$store.commit('setFontScale', 1);
        }
        await this.getConcentradores();
        const myInterval = async () => {
            await this.updateLogs();
            this.allConcentradoresAlarmes();
            this.ultimaAtualizacao = new Date();
            if (this.time === null) return;
            this.time = setTimeout(() => {
                myInterval();
            }, 5000);
        }
        myInterval();
    },
    beforeDestroy() {
        // reativa o padding de altura da página
        document.getElementById('app-bottom-padding').style.display = 'block';

        clearTimeout(this.time);
        this.time = null;
    },
    computed: {
        concentradoresByArea() {
            let areas = {};
            for (let c of this.allConcentradores) {
                if (c.area.nZoom !== this.nZoom && this.nZoom !== 0) continue;
                else if (areas[c.idArea]) areas[c.idArea].push(c);
                else areas[c.idArea] = [c];
            }
            return Object.entries(areas);
        },
        alarmes() {
            return this.logs.filter(l => l.nomeConcentrador === this.concentradorAlarme.nome);
        },
    },
    methods: {
        formatter (date) {
            if (!date) return '-';
            return dayjs(date).locale('pt-br').format('DD/MM/YYYY HH:mm:ss');
        },
        toggleLegenda() {
            this.showLegenda = !this.showLegenda;
        },
        toggleSound() {
            this.makeSound = !this.makeSound;
            this.makeAlarmSound();
            localStorage.setItem('utilities/sinotico/som', this.makeSound);
        },
        makeAlarmSound() {
            if (this.makeSound) this.audio.play();
        },
        updateLogs() {
            return axios.get(api.v1.alarme.online(1, -1, '{}', 'dataHoraInicial', false)).then(res => {

                this.logs = res.data.rows.filter(l => {
                    return l.classeAlarme !== 'Vazamento de gás' || l.severidade !== 'Alerta' || (l.classeAlarme === 'Vazamento de gás' && !res.data.rows.some(a => a.idDetector === l.idDetector && a.classeAlarme === 'Vazamento de gás' && a.severidade === 'Alarme' && !a.dataHoraReconhecimento));
                }).map(i => {
                    const [ extraFull, extraValue, extraTitle ] = (i.extra || '').match(/^(.+)\s+\((.+)\)$/) || ['', '', ''];
                    const extra1 = extraFull && extraTitle.charAt(0).toUpperCase() + extraTitle.slice(1) || 'Extra';
                    const extra2 = extraValue || extraFull || '-';

                    return {
                        id: i.id,
                        descricao: i.descricao,
                        nomeConcentrador: i.nomeConcentrador,
                        nomeConversor: i.nomeConversor,
                        nomeDetector: i.nomeDetector,
                        nomeDispositivo: i.nomeDispositivo,
                        idConcentrador: i.idConcentrador,
                        idConversor: i.idConversor,
                        idDetector: i.idDetector,
                        nomeArea: i.nomeArea,
                        idArea: i.idArea,
                        severidade: i.severidade,
                        classeAlarme: i.classeAlarme,
                        color: this.alarmColors[`${i.severidade} de ${i.classeAlarme}`].color,
                        date: this.formatter(i.dataHoraInicial),
                        dateFinal: this.formatter(i.dataHoraFinal),
                        dateReconhecimento: this.formatter(i.dataHoraReconhecimento),
                        extra1,
                        extra2,
                        msg:`${i.descricao}. Dispositivo: ${i.nomeDispositivo} (${this.concentradoresTipicos[i.nomeConcentrador]}). ${i.extra ? `\n${extra1}: ` + extra2 : ''}`,
                        acked: !!i.dataHoraReconhecimento,
                    }
                });

                const gasAlarmLogs = res.data.rows.filter(l => {
                    return l.classeAlarme === 'Vazamento de gás' && l.severidade === 'Alarme' && l.dataHoraReconhecimento === null;
                });

                if (gasAlarmLogs.length) this.makeAlarmSound();

                this.firstLoaded = true;
            });
        },
        max() {
            this.$el.requestFullscreen({
                navigationUI: 'hide',
            }).then(this.$refs.mapa.resize);
        },
        min() {
            document.exitFullscreen().then(this.$refs.mapa.resize);
        },
        getConcentradores() {
            return axios.get(api.v1.concentrador.list(1, -1, undefined, undefined, undefined, true)).then(res => {
                this.allConcentradores = res.data.rows;
                for (const c of this.allConcentradores) {
                    this.concentradoresTipicos[c.nome] = c.tipicoConcentrador?.nome;
                }
                this.allConcentradoresAlarmes();
            });
        },
        getCorAlarme(concentrador) {
            let alarmesConcentrador = this.logs.filter(l => concentrador.nome === l.nomeConcentrador);
            if(alarmesConcentrador.length > 0) {
                let c =  alarmesConcentrador.filter(ac => !ac.acked).map(ac => ({ classe: ac.classeAlarme, severidade: ac.severidade, cor: ac.color }));
                let cores;
                
                const coresAlarmes = { fill: null, stroke: null };

                cores = c.find(el => el.classe === 'Manutenção');
                if(cores) return { fill: cores.cor, stroke: null };

                cores = c.find(el => el.classe === 'Vazamento de gás' && el.severidade === 'Alerta');
                if(cores) coresAlarmes.fill = cores.cor;
                cores = c.find(el => el.classe === 'Vazamento de gás' && el.severidade === 'Alarme');
                if(cores) coresAlarmes.fill = cores.cor;

                cores = c.find(el => el.classe === 'Dispositivo' && el.severidade === 'Alerta');
                if(cores) coresAlarmes.stroke = cores.cor;
                cores = c.find(el => el.classe === 'Dispositivo' && el.severidade === 'Alarme');
                if(cores) coresAlarmes.stroke = cores.cor;

                cores = c.find(el => el.classe === 'Calibração');
                if(cores) coresAlarmes.stroke = cores.cor;

                return coresAlarmes;
            }
            return { fill: null, stroke: null };
        },
        allConcentradoresAlarmes () {
            this.concentradores = this.allConcentradores.map(ac => {
                let cor = this.getCorAlarme(ac);
                return {
                    marcadorX: ac.marcadorX,
                    marcadorY: ac.marcadorY,
                    nome: ac.nome,
                    nZoom: ac.area.nZoom,
                    cor: cor,
                    tipico: ac.tipicoConcentrador.nome,
                    detectores: ac.detectores.map(d => ({
                        id: d.id,
                        nome: d.nome,
                        localizacao: d.localizacao,
                    })),
                };
            }).filter(c => ((c.cor.fill || c.cor.stroke) && this.nZoom === 0) || c.nZoom === this.nZoom);
        },
        zoom(nZoom) {
            this.nZoom = nZoom;
            this.allConcentradoresAlarmes();
        },
        showAlarmsInfo(concentrador) {
            this.concentradorAlarme = concentrador;
            this.descricaoAlarmeAtiva = -1;
            this.stopMeasurement();
            this.p3 = true;
        },
        showDetalhesAlarme(alarme) {
            this.descricaoAlarmeAtiva = this.descricaoAlarmeAtiva === alarme.id ? -1 : alarme.id;
            if(alarme.classeAlarme === 'Vazamento de gás') {
                this.startMeasurement(alarme.idDetector);
            } else this.stopMeasurement();
        },
        startMeasurement(idDetector) {
            this.stopMeasurement();
            const token = loginService.getBearerToken();
            if (!token) return;
            this.realTimeMeasurementWS = new WebSocket(api.socket.medicoes(token, idDetector));
            this.realTimeMeasurementWS.onmessage = (ev) => {
                try {
                    let { v } = JSON.parse(ev.data)
                    this.medicaoTempoReal = (''+v.toFixed(2)).replace('.', ',');
                } catch (err) {
                    this.medicaoTempoReal = 'ERRO';
                }
            };
            this.realTimeMeasurementWS.onclose = (ev) => {
                this.medicaoTempoReal = 'ERRO: '+ev.code;
            };
        },
        stopMeasurement() {
            if (this.realTimeMeasurementWS) this.realTimeMeasurementWS.close();
            this.realTimeMeasurementWS = null;
            this.medicaoTempoReal = '-';
        },

        async salvarLocalizacao(detector) {
            try {
                this.salvandoDetector[detector.id] = true;
                await axios.put(api.v1.detector.updateLocalizacao, { id: detector.id, localizacao: detector.localizacao });
                this.$set(this.editandoDetector, detector.id, undefined);
            } catch (error) {
                this.$snotify.error(
                    'Ocorreu um erro ao salvar a localização do detector',
                );
            } finally {
                this.salvandoDetector[detector.id] = false;
            }
        },
    }
}
</script>
<style scoped>
    .icon-btn {
        cursor: pointer;
    }

    .relogio {
        height: 26px;
        padding: 0;
        margin: 0 8px 8px 8px;
    }
    .logs,
    .mapa {
        border: 1px solid #ccc;
        flex: 3;
    }
    .mapa {
        background-color: #f5f5f5;
    }
    .sin-con {
        height: calc(100vh - 174px);
        background-color: #fff;
    }

    :fullscreen .sin-con {
        height: calc(100% - 34px);
    }
    .log-container {
        flex: 1;
    }
    .log-content {
        overflow: hidden;
    }
    .legenda {
        line-height: 16px;
        font-size: 12px;
    }
    .rectangle {
        height: 10px;
        width: 25px;
        display: inline-block;
    }
    .msg-log {
        overflow-y: auto;
        height: calc(100% - 35px);
    }
    .b-0 {
        margin-left: -3px;
    }
    .min {
        display: none;
        margin-right: -3px;
    }
    .max {
        display: block;
        margin-right: -3px;
    }
    :fullscreen .min {
        display: block;
    }
    :fullscreen .max {
        display: none;
    }
    .btn-legenda,
    .btn-som {
        line-height: 16px;
    }
    .titulo-legenda {
        display: none;
    }
    @media screen and (max-width: 790px) {
        .sin-con {
            height: calc(100vh - 128px);
        }
        .legenda {
            position: fixed;
            background-color: #fff;
            bottom: 8px;
            left: 8px;
            flex-direction: column;
            padding: 8px;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px;
        }
        .btn-legenda {
            position: fixed;
            bottom: 8px;
            right: 48px;
            padding: 8px !important;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px;
        }
        .titulo-legenda {
            display: block;
        }
        .btn-zoom {
            display: none;
        }
        .btn-som {
            position: fixed;
            bottom: 8px;
            right: 8px;
            padding: 8px !important;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px;
        }
    }

    .grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
    }

    .grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";
    /* This is how textarea text behaves */
    white-space: pre-wrap;
    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
    }

    .grow-wrap>textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;
    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
    }

    .grow-wrap>textarea,
    .grow-wrap::after {
    /* Identical styling required!! */
    border: 1px solid black;
    padding: 0.5rem;
    font: inherit;
    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
    }
</style>
